export default async function validateFunc({ setForm, form, field, value, errors, setErrors, event, interstedInfo, setInfoAboutIRDsChecked, setGeneticTestingForIRDsChecked, setEmergingScienceIRDs, setInterstedInfo, validateField, checkAllField }: { setForm: any; form: any; field: any; value: any; errors: any; setErrors: any; event: any; interstedInfo: any; setInfoAboutIRDsChecked: any; setGeneticTestingForIRDsChecked: any; setEmergingScienceIRDs: any; setInterstedInfo: any; validateField: (field: any, value: any) => Promise<void>; checkAllField: (inputField: any, inputVal: any) => Promise<void>; }): Promise<any> {
  setForm({
    ...form,
    [field]: value
  });
  let erroField = "";
  erroField = field;
  if (field === "infoAboutIRDs" || field === "considerationsToGeneticTestingForIRDs" || field === "emergingScienceAroundIRDs") {
    erroField = "interestedReceiveInfo";
  }
  if (!!errors[erroField])
    setErrors({
      ...errors,
      [erroField]: null
    });


  value = await fieldValidation({ field, value, event, interstedInfo, setInfoAboutIRDsChecked, setGeneticTestingForIRDsChecked, setEmergingScienceIRDs });
  setInterstedInfo(interstedInfo);

  await validateField(field, value);
  await checkAllField(field, value);
  return value;
}

async function fieldValidation({ field, value, event, interstedInfo, setInfoAboutIRDsChecked, setGeneticTestingForIRDsChecked, setEmergingScienceIRDs }: { field: any; value: any; event: any; interstedInfo: any; setInfoAboutIRDsChecked: any; setGeneticTestingForIRDsChecked: any; setEmergingScienceIRDs: any; }): Promise<any> {
  if (field === "infoAboutIRDs") {
    value = (event.target.checked === true ? "infoAboutIRDs" : "");
    interstedInfo["infoAboutIRDs"] = value;
    await setInfoAboutIRDsChecked(value);
  }
  if (field === "considerationsToGeneticTestingForIRDs") {
    value = (event.target.checked === true ? "considerationsToGeneticTestingForIRDs" : "");
    interstedInfo["considerationsToGeneticTestingForIRDs"] = value;
    await setGeneticTestingForIRDsChecked(value);
  }
  if (field === "emergingScienceAroundIRDs") {
    value = (event.target.checked === true ? "emergingScienceAroundIRDs" : "");
    interstedInfo["emergingScienceAroundIRDs"] = value;
    await setEmergingScienceIRDs(value);
  }
  return value;
}

