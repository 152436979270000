import React from 'react';
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout";
import { graphql } from 'gatsby';
import { PageWrapper, StayInformedWrapper, HcpMobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import PatientSignUpForm from '../../../components/Forms/PatientSignUp';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType, ExitPopUpType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import exitPopUpBuilder from '@retina-packages/retina-theme-bootstrap/packages/helpers/exitPopUpBuilder'
import { formPage } from './constants'
import '../../../css/common/common.css'
import retinaConfig from '../../../utils/retinaConfigs';
import HCPSignUpForm from '../../../components/Forms/HCPSignUP';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { HTMLRenderer } from "@retina-packages/retina-theme-bootstrap"
import { deriveMultiLogo, deriveSiteMenus, fullHTMLDataBuilder } from '../../../utils';
import { SignUpWrapper } from './styles';

/**
 * Render forms used in site as standard template
 *
 * @param props jsx properties
 * @returns
 */
const FormPageTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso;
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name;

  let topMenuLabel = ""
  let foorMenuLabel = ""
  let addHcpPrefix = false
  let bannerTitle = ""
  const carouselTitle = ""
  let topNavTitle = ""
  let footerTitle = ""
  let logoLabel = ""
  let successPopupLabel = ""
  let exitPopupTitle = ""
  let layoutAttrs;
  let gtmBackToTopLabel = ""
  let footerClassName = ""
  if (audience === "Professional") {
    topMenuLabel = formPage.hcpMainMenu
    foorMenuLabel = formPage.hcpFooterMenu
    addHcpPrefix = true
    bannerTitle = formPage.hcpBanner
    topNavTitle = formPage.hcpTopNav
    footerTitle = formPage.hcpFooter
    logoLabel = formPage.hcpSiteLogo
    successPopupLabel = formPage.hcpSignupSucessText
    exitPopupTitle = formPage.hcpExitPopup
    footerClassName = formPage.footerClassNameHCP
    gtmBackToTopLabel = formPage.backToTopGTMSU
    layoutAttrs = {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
  }
  else {
    topMenuLabel = formPage.patMainMenu
    foorMenuLabel = formPage.patFooterMenu
    bannerTitle = formPage.patBanner
    topNavTitle = formPage.patTopNav
    footerTitle = formPage.patFooter
    logoLabel = formPage.patSiteLogo
    successPopupLabel = formPage.patSignupSucessText
    exitPopupTitle = formPage.dtcExitPopup
    gtmBackToTopLabel = formPage.backToTopGTMSI
    footerClassName = formPage.footerClassNameDTC
    layoutAttrs = {... {...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: topMenuLabel, siteFooterMenu: foorMenuLabel, addHCPPrefix: addHcpPrefix });



  const hcpSwitcherTitle = formPage.hcpSwitcher
  let languageConfig = retinaConfig.langConfig
  const pageContext = props.pageContext
  const htmlStaticFilesFormPage = {
    nonSVGImages: pageContext.nonSVGImages,
    svgMediaImages: pageContext.svgMediaImages,
    allMediaDocument: pageContext.documents
  }
  // Reusable components rendering
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle: "", carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle});


  // Data for submission success popup data
  const successPopData = blocks.filter((inf: any) => inf?.relationships?.field_reusable_paragraph?.label === successPopupLabel).map(
    (info: ExitPopUpType) => {
      return exitPopUpBuilder(info);
    }
  ).at(0);

  const siteLogos: any = deriveMultiLogo({blocks: blocks, title:logoLabel})
  const infoProvide = fullHTMLDataBuilder({blocks: blocks, title: formPage.hcpInfoYouprovide})
  const knowledgePower = fullHTMLDataBuilder({blocks: blocks, title: formPage.dtcKnowledgePower})
  const optOutContent = fullHTMLDataBuilder({blocks: blocks, title: formPage.optOutContent})
  const bannerHTML = fullHTMLDataBuilder({ blocks: blocks, title: bannerTitle });

  let form;
  let wrapclassname;
  // Render different form based on pathname
  switch (props?.location?.pathname) {
    case pageContext.urlMap["/stay-informed"]?.pathSlug:
    case `${pageContext.urlMap["/stay-informed"]?.pathSlug}/`:
      wrapclassname = "patient-genetic"
      form = (
        <PatientSignUpForm staticMediaFiles={htmlStaticFilesFormPage} propdata={props} location={props.location} successPopData={successPopData} audience={audience} topContentInfo={knowledgePower} optOutContent={optOutContent} />
      );
      languageConfig = Object.assign({}, {...languageConfig}, {...formPage.patRedirectLangUrl})
      break;
    case pageContext.urlMap["/hcp/hcp/sign-up"]?.pathSlug:
    case `${pageContext.urlMap["/hcp/hcp/sign-up"]?.pathSlug}/`:
      wrapclassname = "hcp-genetic"
      form = (
        <HCPSignUpForm staticMediaFiles={htmlStaticFilesFormPage} propdata={props} location={props.location} successPopData={successPopData} audience={audience} topContentInfo={infoProvide} />
      );
      languageConfig = Object.assign({}, {...languageConfig}, {...formPage.hcpRedirectLangUrl})
      break;
    default:
      form = (
        <></>
      )
      break;
  }

  // Return jsx elements
  return (
    <SignUpWrapper className="sign-up-wrapper">
      <HcpMobileBanner className={`hcpmobilewrapper ${wrapclassname}`}>
        <StayInformedWrapper>
          {props.pageContext !== null && props.pageContext.metaInfo !== null && (
            <MetaTagContainer metaData={props.pageContext.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            location={props.location}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            exitPopData={exitData}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            siteLogos={siteLogos}
            backToTopContent={retinaConfig.backToTopContent}
            gtmBackToTopLabel={gtmBackToTopLabel}
            staticMediaFiles={htmlStaticFilesFormPage}
            hcpValidate={retinaConfig.hcpValidate}
            preIndexUrl = {retinaConfig.preIndexUrl}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            showRefContent={retinaConfig.showRefContent}
            hideRefContent={retinaConfig.hideRefContent}
            footerClassName={footerClassName}
            languageConfig={languageConfig}
            {...layoutAttrs}
          >
            <PageWrapper className="pagewrapper patient-pagewrapper stay-informed">
              <MobileBanner className="mobile-banner clinical-trials-banner">
                <HTMLRenderer data={htmlStaticFilesFormPage} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
              </MobileBanner>
              <div className="o-container information-form">{form}</div>
            </PageWrapper>
          </Layout>
        </StayInformedWrapper>
      </HcpMobileBanner>
    </SignUpWrapper>
  );
}

export default FormPageTemplate;

export const formPageQuery = graphql`
  query MyQuery($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... formPageQuery
      }
    }
  }
`

