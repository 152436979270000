import React, { useState, useRef, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { SignUpWrapper } from './styles'
import HcpSignUpValidation from './Validation/frmValidation'
import RetinaModal from '@retina-packages/retina-theme-bootstrap/packages/components/RetinaModal'
import validateFunc from "./Validation/validateFunc"
import { submitHandller } from "./Submission/submitHandller"
import { hcpValidation } from "./Validation/hcpValidation"
import { checkAllFieldValidate } from "./Validation/checkAllFieldValidate"
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap/packages'


type formProfile = {
  propdata: any
  location: any
  successPopData: any
  audience: any
  staticMediaFiles: any
  topContentInfo: string
}

const HCPSignUpForm = (props: formProfile) => {
  const [disabled, setDisabled] = useState(true);
  const [showExitModal, setShowExitModal] = useState(false)
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const infoAboutIRDsRef = useRef(null);
  const considerationsToGeneticRef = useRef(null);
  const emergingScienceAroundIRDsRef = useRef(null);
  const formField = { 'speciality': '', 'firstname': '', 'lastname': '', 'email': '', 'province': '', 'infoAboutIRDs': '', 'considerationsToGeneticTestingForIRDs': '', 'emergingScienceAroundIRDs': '', 'referencePath': '' }
  const interstedInfoArr = { 'infoAboutIRDs': '', 'considerationsToGeneticTestingForIRDs': '', 'emergingScienceAroundIRDs': '' }
  const [interstedInfo, setInterstedInfo] = useState(interstedInfoArr)
  const [infoAboutIRDsChecked, setInfoAboutIRDsChecked] = useState("")
  const [geneticTestingForIRDsChecked, setGeneticTestingForIRDsChecked] = useState("")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_emergingScienceIRDsChecked, setEmergingScienceIRDs] = useState("")

  const setField = async (event: any, field: any, value: any) => {
     await validateFunc({ setForm, form, field, value, errors, setErrors, event, interstedInfo, setInfoAboutIRDsChecked, setGeneticTestingForIRDsChecked, setEmergingScienceIRDs, setInterstedInfo, validateField, checkAllField })
  }

  const validateField = hcpValidation(interstedInfo, setErrors, errors)

  const checkAllField = checkAllFieldValidate(formField, form, interstedInfo, setDisabled)

  const findFormErrors = async () => {
    return findFormErrorsInner(setDisabled, formField, form, interstedInfo)
  }

  const handleSubmit = async (e: any) => {
    const consider = considerationsToGeneticRef?.current?.value
    const info = infoAboutIRDsRef?.current?.value
    const emerging = emergingScienceAroundIRDsRef?.current?.value
    await submitHandller({ e, findFormErrors, setErrors, setDisabled, form, info, consider, emerging, setShowExitModal })
  }

  useEffect(()=>{
    const escapeEvent = escapeEventBase(setShowExitModal)
    // Hide modal on pressing escape key for accessibility.
    document.addEventListener('keydown', escapeEvent)
    // Hide modal on outside click.
    document.addEventListener('click', hideExitOnClick(setShowExitModal))
  }, [])

  return (
    <>
      <RetinaModal
        location={props.location}
        redirectlink={"/"}
        modalData={{ "headerTxt": "", "description": props.successPopData.description, "footerTxt": "", "modalclass": "HCP-signup", "label": "hcp_sign_up_form_success" }}
        show={showExitModal}
        closeButtonLabel={'Close'}
        staticMediaFiles={props.staticMediaFiles}
      />
      <SignUpWrapper className="signwrapper">
        <div className="sign-up-title form-para o-column--full">
          <HTMLRenderer html={props.topContentInfo} data={props.staticMediaFiles} tagName='div'/>
          <div className="o-container-hcp form-header-container">
            <div className="information-form">
              <Form className="signup-form" onSubmit={handleSubmit} action="#">
                <div className="form-required"><p>All fields are required.</p></div>
                <div className="apos-area">
                  <Form.Group className="apos-area-widget-wrapperp" controlId="formSpeciality">
                    <div className="form-field__control gtm-form-signup">
                      <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Specialty</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Select name="speciality" size="sm"
                          aria-required="true"
                          className="apos-forms-input form-field__input optional-form-dw optional-dw-hcp"
                          isInvalid={!!errors.speciality}
                          onBlur={(e: any) => setField(e, 'speciality', e.target.value)}
                          { ... errors.speciality ? {"aria-invalid": true} : {}}
                          { ... errors.speciality ? {"aria-describedby": 'speciality-error'} : {}}
                        >
                          <option value="">Select a specialty</option>
                          <option value="Retina Specialist">Retina Specialist</option>
                          <option value="IRD Specialist">IRD Specialist</option>
                          <option value="Low Vision Specialist">Low Vision Specialist</option>
                          <option value="Genetic Counselor">Genetic Counselor</option>
                          <option value="Ophthalmologist">Ophthalmologist</option>
                          <option value="Pediatric Ophthalmologist">Pediatric Ophthalmologist</option>
                          <option value="Optometrist">Optometrist</option>
                          <option value="Other">Other</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid' role="alert" id="speciality-error">
                          {errors.speciality}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </div>
                  </Form.Group>

                  <Form.Group className="apos-area-widget-wrapperp" controlId="formFirstName">
                    <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>First name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control name="firstName" type="text"
                        aria-required="true" className='form-field__input'
                        isInvalid={!!errors.firstname}
                        onBlur={(e: any) => setField(e, 'firstname', e.target.value)}
                        { ... errors.firstname ? {"aria-invalid": true} : {}}
                        { ... errors.firstname ? {"aria-describedby": 'firstname-error'} : {}}
                      />
                      <Form.Control.Feedback type='invalid' role="alert" id="firstname-error">
                        {errors.firstname}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="apos-area-widget-wrapperp" controlId="formLastName">
                    <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Last name</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control name="lastName" type="text"
                        aria-required="true" className='form-field__input'
                        isInvalid={!!errors.lastname} onBlur={(e: any) => setField(e, 'lastname', e.target.value)}
                        { ... errors.lastname ? {"aria-invalid": true} : {}}
                        { ... errors.lastname ? {"aria-describedby": 'lastname-error'} : {}}
                      />
                      <Form.Control.Feedback type='invalid' role="alert" id="lastname-error">
                        {errors.lastname}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="apos-area-widget-wrapperp" controlId="formEmail">
                    <div className="apos-area-widget-wrapperp">
                      <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Email address</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control name="email"
                          type="email"
                          aria-required="true"
                          className='form-field__input'
                          isInvalid={!!errors.email}
                          onBlur={(e: any) => setField(e, 'email', e.target.value)}
                          { ... errors.email ? {"aria-invalid": true} : {}}
                          { ... errors.email ? {"aria-describedby": 'email-error'} : {}}
                         />
                        <Form.Control.Feedback type='invalid' role="alert" id="email-error">
                          {errors.email}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </div>
                  </Form.Group>

                  <Form.Group className="apos-area-widget-wrapperp" controlId="formProvince">
                    <div className="form-field__control gtm-form-signup dw-province">
                      <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Province/Territory</Form.Label>
                      <Form.Select name="province" size="sm"
                        aria-required="true"
                        className="apos-forms-input form-field__input optional-form-dw optional-dw-hcp"
                        isInvalid={!!errors.province}
                        onBlur={(e: any) => setField(e, 'province', e.target.value)}
                        { ... errors.province ? {"aria-invalid": true} : {}}
                        { ... errors.province ? {"aria-describedby": 'province-error'} : {}}
                      >
                        <option value="">Province/Territory</option>
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="ON">Ontario</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                      </Form.Select>
                      <Form.Control.Feedback type='invalid' role="alert" id="province-error">
                        {errors.province}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>

                  <Form.Group className={`apos-area-widget-wrapperp check-optional topic-text ${errors.interestedReceiveInfo ? 'invalid-topic' : ''}`} controlId="formCheckbox">
                    <p className="o-text--normal radio-btn-title"> What topics are you interested in receiving information about? (select all that apply)</p>

                    <Form.Check aria-required="true" ref={infoAboutIRDsRef} className='custom-checkbox-btn checkbox-section register-checkbox' label="Info about IRDs (disease education, diagnostic challenges, etc.)" name="infoAboutIRDs" type="checkbox" id="check-infoAboutIRDs" value={infoAboutIRDsChecked} isInvalid={!!errors.considerationsToGeneticTestingForIRDs} onClick={(e: any) => setField(e, 'infoAboutIRDs', e.target.value)} />

                    <Form.Check aria-required="true" ref={considerationsToGeneticRef} className='custom-checkbox-btn' label="Considerations for genetic testing (why test, how to test, etc.)" name="considerationsToGeneticTestingForIRDs" type="checkbox" id="check-considerationsToGeneticTestingForIRDs" value={geneticTestingForIRDsChecked} isInvalid={!!errors.considerationsToGeneticTestingForIRDs} onClick={(e: any) => setField(e, 'considerationsToGeneticTestingForIRDs', e.target.value)} />

                    <Form.Control.Feedback type='invalid' role="alert">
                      {errors.interestedReceiveInfo}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="o-container o-column-hid-container mb-3 bothid" controlId="validationReferencePath">
                    <Form.Control type="text" name="referencePath" className="refpath bothid" onChange={(e: any) => { setField(e, 'referencePath', e.target.value); }} />
                  </Form.Group>
                </div>
                <Button id="submitButton" className="o-button o-button--primary form-field__submit gtm-form-HCP-Signup" type="submit" disabled={disabled} >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </SignUpWrapper>
    </>
  )
}

export default HCPSignUpForm;

function hideExitOnClick(setShowExitModal: any): (this: Document, ev: MouseEvent) => any {
  return (e: any) => {
    const target = e.target
    const role = target && target.getAttribute('role')
    if (role && role === 'dialog') {
      setShowExitModal(false)
    }
  }
}

function escapeEventBase(setShowExitModal: any) {
  return (e: any) => {
    // Hide modal on pressing escape key for accessibility.
    if (e.key === "Escape") {
      setShowExitModal(false)
    }
  }
}

async function findFormErrorsInner(setDisabled: any, formField: { speciality: string; firstname: string; lastname: string; email: string; province: string; infoAboutIRDs: string; considerationsToGeneticTestingForIRDs: string; emergingScienceAroundIRDs: string; referencePath: string }, form: any, interstedInfo: any) {
  setDisabled(true)
  const newErrors = []
  for (const key in formField) {
    const field = key
    const value = form[key] === undefined ? "" : form[key]
    const fieldValidation = await HcpSignUpValidation({ field, value, interstedInfo })
    if (fieldValidation.isError === true) {
      newErrors.push({ "field": [fieldValidation.field], "Errormsg": fieldValidation.Errormsg })
    }
  }
  return newErrors
}

