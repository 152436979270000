import styled from "styled-components";
import { device } from "@retina-packages/retina-theme-bootstrap/packages/css/device";

export const SignUpWrapper = styled.div`

  .hcp-genetic {
    .internal-banner {
      .internal-banner__contant {
        .o-container {
          @media ${device.laptopPros} {
            padding: 0 3rem;
          }
          margin: 0 auto;
          padding: 0 2rem;
        }
      }
    }
    &.hcpmobilewrapper {
      .show {
        +.main {
          padding-top: 0;
        }
      }
    }
    .mobile-banner {
      &.clinical-trials-banner {
        @media ${device.mobileMedium} {
          height: 310px;
        }
      }
      @media ${device.ipadLandscape} {
        height: 470px;
      }
    }
    .headerwraphead {
      .containerWidth {
        .topnav {
          button {
            @media ${device.mobileMedium} {
              top: 380px;
            }
          }
        }
      }
    }

    .header-inner {
      .navbar-nav {
        .navitemstyle {
          @media ${device.desktopsignup} and  ${device.midDesktopMax} {
            padding: 0 10px;
          }
        }
      }

      .navbar {
        .navbar-collapse {
          .navbar-nav {
            @media ${device.mobileMedium} {
              min-height: 450px;
            }
          }
        }
      }
    }

    .stay-informed {
      &.patient-pagewrapper {
        .internal-banner {
          @media ${device.laptopL} {
            height: 450px;
          }

          .internal-banner__image {
            .desktop-image {
              @media ${device.desktopStart} {
                height: 100%;
              }
              img {
                @media ${device.tabletMax}{
                  height: 470px;
                  object-position: 90% 0%;
                }
              }
            }
          }
        }

        .o-container {
          h1 {
            @media ${device.tablet} ${device.desktopmenu} {
              max-width: 70vw !important;
            }
            @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
              margin-left: 10px;
            }
          }
          &.information-form {
            @media ${device.laptopMedium} {
              padding-bottom: 0;
            }

            @media ${device.laptopPros} {
              padding: 0 2.7rem;
            }
          }
        }

        .signwrapper {
          .o-column--full {
            .form-header-container {
              .information-form {
                @media ${device.laptopMedium} {
                  padding-bottom: 0;
                }
              }
            }
          }

          .apos-area {
            .apos-area-widget-wrapperp {
              .form-select {
                &.is-invalid {
                  border: 3px solid #e00707;
                }
              }

              .input-group {
                .form-control {
                  &.is-invalid {
                    border: 3px solid #e00707;
                  }
                }
              }
            }
          }
        }
      }
    }
    .stayinformedfooterhcp {
      @media ${device.laptopMedium} {
        margin: 8rem auto 0;
      }
      .o-backtotop-btn {
        @media ${device.laptopMedium} {
          top: -100px !important;
        }
      }
    }
  }

  .patient-genetic {
    .signup-form {
      hr {
        @media ${device.miniTopDeskMax} {
          opacity: 0.75;
        }
      }
    }
    .stayinformedfooter{
      @media ${device.tablet} and ${device.laptopMedium} {
        padding: 0;
      }
      @media ${device.laptopMedium} {
        margin: 8rem auto 0;
      }
      .o-backtotop-btn {
        @media ${device.laptopMedium} {
          top: -100px !important;
        }
      }
      .footercontainer {
        footer {
          > .row {
            padding-top: 0;
            @media ${device.tablet} and ${device.laptopMedium} {
              padding: 1rem 1rem 3rem;
            }
          }
        }
      }
    }
    .stay-informed {
      &:before {
        @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
          top: 0;
        }
      }
      &.patient-pagewrapper {
        .mobile-banner {
          @media ${device.laptopMedium} {
            height: 470px;
          }
        }
        .internal-banner {
          height: 100%;

          .internal-banner__image {
            @media ${device.laptopMedium} {
              height: 470px;
            }

            @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
              height: 250px;
            }

            .desktop-image {
              height: 100%;
              img {
                @media ${device.tablet}{
                  object-position: 90% 0%;
                }
                @media ${device.ipadLandscapemin}{
                  object-position: center;
                }
              }
            }
          }

          .internal-banner__contant {
            .o-container {
              @media ${device.ipadLandscapemin} {
                padding: 0 5rem;
              }
              @media ${device.desktopStart} {
                padding: 0 2rem;
              }
              h1 {
                @media ${device.laptopMedium} {
                  padding-left: 0 !important;
                }
                @media ${device.laptop} {
                  max-width: 70% !important;
                  padding-left: 25px !important;
                }
                @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                  margin-top: 15px;
                  padding-left: 25px !important;
                }
              }
            }
          }
        }

        .o-container {
          &.information-form {
            @media ${device.laptopMedium} {
              padding-bottom: 0;
            }
          }
        }
      }
      .o-internal--h2 {
        h2 {
          @media ${device.laptopMedium} {
            line-height: 3rem;
          }
        }
      }
    }

    // .footer {
    //   .o-backtotop-btn {
    //     @media ${device.laptopMedium} {
    //       top: -100px;
    //     }
    //   }

    //   .footercontainer {
    //     @media ${device.laptopMedium}{
    //       padding: 0px 0px 6rem;
    //     }
    //     @media ${device.laptopMedium} {
    //       margin: 75px auto 0;
    //     }

    //     footer {
    //       >.row {
    //           padding: 1rem 0;

    //         @media ${device.tablet} and ${device.laptopMedium} {
    //           padding: 1rem 1rem;
    //         }
    //         .left-footer{
    //           .FooterText1{
    //             margin-bottom: 0;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    .o-container-inner {
      .information-form .signupform {
        .apos-area  {
          .gtm-form-signup {
            .radio-btn-title {
              margin-bottom: 1rem;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
`;
