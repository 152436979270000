import HcpSignUpValidation from './frmValidation';

export function checkAllFieldValidate(formField: { speciality: string; firstname: string; lastname: string; email: string; province: string; infoAboutIRDs: string; considerationsToGeneticTestingForIRDs: string; emergingScienceAroundIRDs: string; }, form: any, interstedInfo: any, setDisabled: any) {
  return async (inputField: any, inputVal: any) => {
    let newErrors = false;
    for (const key in formField) {
      const field = key;
      const validUnd = (form[field] === undefined) ? "" : form[key]
      const value = field === inputField ? inputVal : validUnd;
      const fieldValidation = await HcpSignUpValidation({ field, value, interstedInfo });
      if (fieldValidation.isError === true) {
        newErrors = true;
      }
    }
    newErrors === true ? setDisabled(true) : setDisabled(false);
  };
}
