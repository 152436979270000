import PatientSignUpSubmission from '.'
import { ErrorMessages } from "../../types"

export async function submitHandller({ e, findFormErrors, setErrors, setDisabled, form, isOver18Ref, agreedTermsRef, setShowExitModal }: { e: any; findFormErrors: () => Promise<{ field: string[]; Errormsg: ErrorMessages; }[]>; setErrors: any; setDisabled: any; form: any; isOver18Ref: any; agreedTermsRef: any; setShowExitModal: any; }): Promise<void> {
  e.preventDefault()
  if (form.referencePath !== undefined && form.referencePath !== "") {
    return;
  }
  const experienceGeneTestingVal = form.experienceGeneTesting !== undefined ? form.experienceGeneTesting : "";

  const newErrors = await findFormErrors()
  if (Object.keys(newErrors).length > 0) {
    const errorObject = newErrors.reduce((obj, item: {
      field: any
      Errormsg: string
    }) => ({
      ...obj,
      [item.field]: item.Errormsg
    }), {})
    setErrors(errorObject)
  } else {
    setDisabled(true)
    const result = await PatientSignUpSubmission({
      email: form.email,
      province: form.province,
      isOver18: isOver18Ref.current.value,
      agreedTerms: agreedTermsRef.current.value,
      experienceGeneTesting: experienceGeneTestingVal
    })
    if (result === true) {
      setShowExitModal(true);
      setTimeout(()=> {
        const modalCloseButtons = document.querySelectorAll('.modal-thankyou .button-close, .modal-thankyou .btn-close-link')
        for (let i = 0; i < modalCloseButtons.length; i++) {
          const el = modalCloseButtons.item(i);
          el.addEventListener('click', () => {
              setShowExitModal(false)
          })
        }
      }, 1000)
    }
  }
}
