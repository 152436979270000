export async function validateFunc({ setForm, form, field, value, errors, setErrors, event, setIsOver18Checked, setIsAgreedTermsChecked, validateField, checkAllField }: { setForm: any; form: any; field: any; value: any; errors: any; setErrors: any; event: any; setIsOver18Checked: any; setIsAgreedTermsChecked: any; validateField: (field: any, value: any) => Promise<void>; checkAllField: (inputField: any, inputVal: any) => Promise<void>; }): Promise<any> {
  setForm({
    ...form,
    [field]: value
  });
  if (!!errors[field])
    setErrors({
      ...errors,
      [field]: null
    });

  if (field === "isOver18") {
    value = event.target.checked === true ? "over18" : "";
    await setIsOver18Checked(value);
  }
  if (field === "agreedTerms") {
    value = event.target.checked === true ? "agreed" : "";
    await setIsAgreedTermsChecked(value);
  }
  await validateField(field, value);
  await checkAllField(field, value);
  return value;
}
