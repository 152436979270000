export const formPage: any = {
  hcpMainMenu: "Main Navigational Menu HCP GCSO",
  patMainMenu: "Main Navigational Menu Consumer GCSO",
  hcpFooterMenu: "Footer Navigation Menu HCP GCSO",
  patFooterMenu: "Footer Navigation Menu Consumer GCSO",
  hcpFooter: "Retina Global Footer HCP - Canada",
  patFooter: "Footer DTC HTML Canada",
  dtcExitPopup: "Retina Canada Exit Popup HTML",
  hcpExitPopup: "Retina Canada HCP Exit Popup HTML",
  hcpSwitcher: "HCP Switcher Modal Pop Up",
  hcpTopNav: "Top Navigational Menu HCP - Canada",
  patTopNav: "Top Navigation Menu Retina Canada HTML DTC",
  hcpSiteLogo: "Site logos HCP",
  patSiteLogo: "Site logos DTC",
  patBanner: "Banner - HTML - Sign up - DTC - Canada",
  hcpBanner: "Banner - HTML - Sign up - HCP - Canada",
  hcpSignupSucessText: "Sign up Thank you message HTML Canada HCP",
  patSignupSucessText: "Sign up Thank you message HTML Canada DTC",
  hcpInfoYouprovide: "HCP Sign Up - Information You provide - HTML - Canada",
  dtcKnowledgePower: "Knowledge is power - DTC Signup Canada",
  optOutContent: "Sign Up Opt Out DTC Canada",
  hcpRedirectLangUrl: {
    "redirectUrl": {
      "en": "/hcp/sign-up",
      "fr": "/fr/hcp/sign-up"
    }
  },
  patRedirectLangUrl: {
    "redirectUrl": {
      "en": "/stay-informed",
      "fr": "/fr/stay-informed"
    }
  },
  backToTopGTMSU: "Back to top - signup hcp",
  backToTopGTMSI: "Back to top - signup",
  footerClassNameDTC: "footer  privacypolicyfooter stayinformedfooter common-footer",
  footerClassNameHCP: "footer  privacypolicyfooter  stayinformedfooterhcp common-footer"
}
