import HcpSignUpSubmission from '.';
import { ErrorMessages } from '../../types';

export async function submitHandller({ e, findFormErrors, setErrors, setDisabled, form, info, consider, emerging, setShowExitModal }: { e: any; findFormErrors: () => Promise<{ field: string[]; Errormsg: ErrorMessages; }[]>; setErrors: any; setDisabled: any; form: any; info: any; consider: any; emerging: any; setShowExitModal: any; }): Promise<void> {
  e.preventDefault();
  if (form.referencePath) {
    return;
  }
  const newErrors = await findFormErrors();
  if (Object.keys(newErrors).length > 0) {
    const errorObject = newErrors.reduce((obj, item: {
      field: any;
      Errormsg: string;
    }) => ({
      ...obj,
      [item.field]: item.Errormsg
    }), {});
    setErrors(errorObject);
  } else {
    setDisabled(true);
    const dataPayLoad = {
      'speciality': form.speciality, 'firstname': form.firstname, 'lastname': form.lastname, 'email': form.email, 'province': form.province, 'infoAboutIRDs': info, 'considerationsToGeneticTestingForIRDs': consider, 'emergingScienceAroundIRDs': emerging, 'segmentation': [{}]
    };

    if (info !== undefined && info.trim() === "infoAboutIRDs") {
      dataPayLoad.segmentation.push({
        "qid": "5152",
        "answerType": "ID",
        "aid": ["1"]
      });
    } else {
      dataPayLoad.segmentation.push({
        "qid": "5152",
        "answerType": "ID",
        "aid": ["2"]
      });
    }

    if (consider !== undefined && consider.trim() === "considerationsToGeneticTestingForIRDs") {
      dataPayLoad.segmentation.push({
        "qid": "5153",
        "answerType": "ID",
        "aid": ["1"]
      });
    } else {
      dataPayLoad.segmentation.push({
        "qid": "5153",
        "answerType": "ID",
        "aid": ["2"]
      });
    }


    dataPayLoad.segmentation.shift();

    const result = await HcpSignUpSubmission({ dataPayLoad });
    showThankYou(result, setShowExitModal);
  }
}

function showThankYou(result: boolean, setShowExitModal: any) {
  if (result === true) {
    setShowExitModal(true);
    setTimeout(()=> {
      const modalCloseButtons = document.querySelectorAll('.modal-thankyou .button-close, .modal-thankyou .btn-close-link');
      for (let i = 0; i < modalCloseButtons.length; i++) {
        const el = modalCloseButtons.item(i);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        el?.addEventListener('click', (event: any) => {
          setShowExitModal(false);
        });
      }
    }, 1000)
  }
}

