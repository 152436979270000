import { ErrorObj, ErrorMessages } from "../../types";

export interface Props {
	field?: any;
	value?: any;
}

/** Rendering template for Patient Sign Up Validation
 *
 * @param param0 props
 * @returns
 */
const PatientSignUpValidation = async ({ field, value }: Props): Promise<ErrorObj> => {
	let errorObj: ErrorObj = { "isError": false, "field": field, "value": value, "Errormsg": ErrorMessages.noError }
	errorObj = validateFunc({ field, value, errorObj });
	return errorObj;
}

export default PatientSignUpValidation

function validateFunc({ field, value, errorObj }: { field: any; value: any; errorObj: ErrorObj; }): ErrorObj {
	if (field === "email") {
		const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
		if (value === "") {
			errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.emailError };
		}
		if (value !== "") {
			const validEmail = emailReg.test(String(value));
			if (!validEmail)
				errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.emailError };
		}
	}
	if (field === "province" && value === "") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.provinceError };
	}
	if (field === "isOver18" && value !== "over18") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.agreedFieldError };
	}
	if (field === "agreedTerms" && value !== "agreed") {
		errorObj = { "isError": true, "field": field, "value": value, "Errormsg": ErrorMessages.agreedFieldError };
	}
	return errorObj;
}
