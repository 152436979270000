import styled from "styled-components";
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device'

export const SignUpWrapper = styled.div`

	$font-size:1.8rem;
	$line-height:2.6rem;

	@mixin font($font-size,$line-height){
		font-size:$font-size;
		line-height:$line-height;
	}

	.o-column--full{
		width: 100%;

		.signup-form{
			label{
				display: inline-block;
				font-weight: 500;
				color: #000000;
				text-align: left;
			}
		}

		.o-container-inner {
			max-width: 1208px;
			margin-left: auto;
			margin-right: auto;
		}

		.o-container {
			max-width: 1208px;
			margin-left: auto;
			margin-right: auto;

			@media ${device.ipadLandscape} {
				max-width: 718px;
			}

			h2 {
				font-size: 4.2rem;
				font-weight: 700;
				line-height: 5rem;
				color: #000000;
				text-align: center;

				strong {
					font-weight: normal;
				}
			}

			.btn-container-submit {
				p {
					font-size:13px;
				}
			}
		}

		.sign-up-paragraph {
			max-width: 768px;
			margin: auto;

			p {
				font-size: 2.2rem;
				line-height: 3.2rem;
				text-align: center;
				color: #000000;
				font-family: "Gotham", Arial, Helvetica, sans-serif;

				strong {
					font-weight: normal;
				}

				&:first-child {
					font-weight: 500;
					font-family: "Gotham", Arial, Helvetica, sans-serif;
					@media ${device.laptopMedium} {
						margin-bottom: 0;
					}
				}

				&:nth-child(2) {
					padding-top: 22px;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.6rem;
						padding-bottom: 20px;
						margin-bottom: 0;
					}
				}
			}

		}

			.signup-btn-paragraph {
				max-width: 768px;
				margin: auto;

				p {
					padding-bottom: 0;
					font-size: 2.2rem;
					line-height: 3.2rem;
					text-align: center;
					color: #00000;
					font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.6rem;
						margin-bottom: 0;
					}

					a {
						color:#000000;

						&:hover {
							font-weight: bold !important;
						}

					}
				}
			}

			.o-container-inner {
				.information-form {
					padding-bottom: 120px;
					padding-top: 38px;

					.form {
						max-width: 568px;
						margin: auto;
					}

					.signupform {
						.apos-area {
							.required-field-text {
								font-size: 1.8rem;
								line-height: 2.2rem;
								font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
								color: #000000;
								padding-bottom: 24px;
								margin-bottom:0;
							}

							.gtm-form-signup {
								margin-bottom: 40px;

								.o-text--normal {
									@include font;
								}

								.radio-btn-title {
									padding-bottom: 30px;
									margin-bottom: 0px;
								}

								.BgnZo .o-column--full {
									width: 100%;
								}

								.form-label {
									margin-bottom: 0.5rem;

									@media ${device.ipadLandscape} {
										font-size: 1.8rem;
										line-height: 2.6rem;
									}
								}

								.invalid-feedback {
									font-size: 14px;
									color: #e00707;
									font-weight: 400;
								}

								.input-group {
									.form-control {
										&:invalid {
											background:transparent;
										}

										&:valid {
											background:transparent;
										}

										&:focus {
												box-shadow: none !important;
										}

										&:focus-visible {
												outline: -webkit-focus-ring-color auto 1px;
										}
									}
								}

								.optional-form-dw {
									box-shadow: none !important;

									&:focus-visible {
										outline: -webkit-focus-ring-color auto 1px;
									}
								}

								&:focus{
										box-shadow: none !important;
								}
							}

							.btn-primary {
								&:focus {
									box-shadow: none !important;
								}
							}

							.form-field__input {
								font-size: 2.2rem;
								line-height: 3.2rem;

								&.is-invalid {
									border: 3px solid #e00707;
								}
							}

							.optional {
									display: flex;
									flex-direction: column;
							}
						}

					}
				}

			}

    }

    .form-para {
      padding: 96px 0 0 0;
    }

    .o-text--normal{
			font-size:1.8rem;
			line-height:2.6rem;

			@media ${device.ipadLandscape} {
				font-size: 1.4rem;
				line-height: 1.8rem;
			}

			&.radio-btn-title {
				@media ${device.laptopMedium} {
					font-size: 1.8rem;
					line-height: 2.6rem;
				}
			}
    }

    .custom-radio1 {
			margin-bottom: 25px;
			line-height: 26px !important;

			input {
				margin-top:-2px;
			}
			label {
				margin-left:-22px;

			}
    }

    .custom-radio2 {
			padding-top: 0 !important;
			line-height: 26px !important;
			margin-bottom: 25px;

			input {
				margin-top:10px;
			}

			label {
				line-height:26px !important;
				margin-left:-22px;
			}
    }

    .custom-radio3 {
			line-height: 26px;
			margin-bottom: 0px;
			padding-top: 0 !important;

			input {
				margin-top:10px;
			}

			label {
				line-height:26px;
				width: 103%;
				margin-left:-22px;
			}
    }

    .custom-checkbox-btn {
			position: relative;
			padding: 4px 0 0 27px;
			cursor: pointer;
			user-select: none;
			font-size: 1.8rem;
			line-height: 3.2rem;

			.form-check-label {
				color:black !important;
				padding-left: 20px;
			}

			input {
				height:32px;
				width:32px;

			}
    }

    .signup-form {

			.form-check-input[type="radio"] {
				&:focus {
					box-shadow: 0 0 0 0.25rem rgb(42 111 243 / 100%);
					border: solid 2px #0151C1 !important;
				}
			}

			.o-column-btn-container {
				display:flex;
				flex-direction: column;
				margin:auto;
				margin-top: -78px;
				max-width:768px;

				@media ${device.ipadLandscape} {
					padding: 0;
				}

				.btn-primary{
					border: 1px solid #fff;
					&:focus {
						box-shadow: 0 0 0 0.25rem rgb(42 111 243 / 100%);
					}
				}

				.o-button {
					width: 22%;
					margin: auto;
					margin-top: 38px;
				}

				.o-column-hid-container {
					.refpath {
						display: none;
					}
				}
			}

			.o-header {
				padding-bottom: 30px;
				font-family: "Gotham", Arial, Helvetica, sans-serif;

				h2 {
					strong {
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;
					color: #000000;
					text-align: center;
					}
				}
			}

			.sign-up-paragraph {
				font-size: 22px;
				font-weight: 500;

				p {
					font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

					strong {
						font-size: 22px;
						font-weight: 500;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    						line-height: 2.6rem;
						}
					}
				}
			}

			.o-internal--h2 {
				h2{
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;
					color: #000000;
					text-align: center;
				}

				.signup-top-paragraph{
					text-align: center;

					p{
						color: #000000;
					}
				}
			}

			background-color: #fff;
			background-clip: padding-box;
			border-radius: 0.3rem;

			hr {
				margin-top:0;
				margin-bottom: 50px;
				color: #8000BE;
				background-color: #8000BE;
				opacity:1;
			}

			.form-check-input[type="checkbox"] {
				border-radius: unset;
				border: solid 2px #8000be;

				&.is-invalid {
					border: 3px solid #e00707;
				}
			}

			.form-check-input[type="radio"] {
				border: solid 2px #8000be;
				background-color: #fff;
				font-size: 32px;
			}

			.form-check-input[type="radio"]:checked {
				border: solid 2px #8000be;
				background-color: #8000be;
			}

			.custom-checkbox-btn {
				margin-left: 21px;

				.form-check-label {
					font-family: "Gotham-Book", Arial, Helvetica, sans-serif;

				}

			}

			.check-container-18 {
				padding:0;
			}

			.check-read-all {
				display: flex;
				margin-top: 20px;
				label{
					width: 92%;
				}
			}

			.custom-check-btn-container {
				border-radius:unset;

				.check-18{
					font-family: unset;
					padding: 0;
					margin-left: 0;

					.form-check-input{
						border-radius: 4px;

						&:focus {
							box-shadow: 0 0 0 0.25rem rgb(42 111 243);
    					border: solid 2px #0151C1;
						}
					}
				}

				input[type="checkbox"]:checked {
					border: solid 2px #8000be;
					background-color: #8000be;
				}

				.custom-checkbox-btn{
					.form-check-input{
						margin-left:0;
					}
				}

				.invalid-feedback {
					font-size: 14px;
					color: #e00707;
					font-weight: 400;
					display: block;
					position: relative;
					left: 20px;

					&:nth-child(4) {
						position: relative;
						left: 52px;
					}
				}
			}

			.signup-btn-container{
				margin: auto;

			}

			.form-field__input{
					border-radius: 4px;
					border: 1px solid #8000BE;
					padding: 10px 16px;
					font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
			}

    }

    .o-button {
			margin-top: 30px;
			margin-bottom: 100px;
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 2.4rem;
			text-decoration: none;
			z-index: 1;
			position: relative;
			display: inline-block;
			vertical-align: top;
			overflow: hidden;
			padding: 16px 22px;
			border: none;
			letter-spacing: 0;
			border-radius: 6px;
			background-color: #8000BE;
			cursor: pointer;

			&:hover {
				background:#FFF;
				transition: ease 0.3ms;
				color: #8000BE;
				border-color: #fff;
				font-weight: 700;
			}

			&:disabled {
				opacity: 0.3;
				pointer-events: none;
				background-color: #300064;
				cursor: default;

				&::after {
					opacity: 0.3;
				}
			}

			.o-button--primary {
				background: #8000BE;
				color: #fff;
				border: 1px solid #fff;
			}

    }

    @media ${device.tabletMax} {
			.o-button--primary {
				font-size: 1.8rem;
				line-height: 2.6rem;
				padding: 10px 22px;
			}
    }

		@media (max-width: 991.98px) {
			.o-button--primary {
				font-size: 1.8rem;
				line-height: 2.6rem;
			}

			.signup-form {
				h2 {
					padding-bottom: 15px;
					margin-bottom: 0px;
				}
			}

			.o-internal--h2 {
				padding-bottom: 0px !important;

				strong {
					font-size: 2.5rem !important;
					line-height: 3rem;
				}
			}

			.sign-up-paragraph {
				p:first-child {
					font-size: 1.8rem;
					line-height: 2.6rem;
				}
			}
		}

		@media ${device.mobileMedium} {
			.o-button--primary {
				width: unset !important;
			}
		}
	}

`;
