import axios from 'axios';

export interface Props {
  dataPayLoad: any;
}

/** Rendering template for HCP Sign Up Submission Form
 *
 * @param props props
 * @returns
 */
const HcpSignUpSubmission = async (props: Props) => {
  const { dataPayLoad } = props;

  const leadUrl = `${process.env.HSIGNURL}`
  if  ((dataPayLoad.considerationsToGeneticTestingForIRDs === "" && dataPayLoad.infoAboutIRDs === "" ) ||
      dataPayLoad.speciality === "" ||
      dataPayLoad.firstname === "" ||
      dataPayLoad.lastname === "" ||
      dataPayLoad.email === "" ||
      dataPayLoad.province === ""
  ) {
    return false
  }
  // Gather data.
  const bodyFormData = new FormData();
  bodyFormData.append('preferred_language', 'en')
  bodyFormData.append('specialty', dataPayLoad.speciality)
  bodyFormData.append('first_name', dataPayLoad.firstname)
  bodyFormData.append('last_name', dataPayLoad.lastname)
  bodyFormData.append('email_address', dataPayLoad.email)
  bodyFormData.append('province_territory', dataPayLoad.province)
  bodyFormData.append('communication_preference_1', "Active")

  if (dataPayLoad.infoAboutIRDs !== "") {
    bodyFormData.append('info_about_irds_disease_education_diagnostic_challenges_etc_', '1')
  } else {
    bodyFormData.append('info_about_irds_disease_education_diagnostic_challenges_etc_', '2')
  }

  if (dataPayLoad.considerationsToGeneticTestingForIRDs !== "") {
    bodyFormData.append('considerations_for_genetic_testing_why_test_how_to_test_etc_', '1')
  } else {
    bodyFormData.append('considerations_for_genetic_testing_why_test_how_to_test_etc_', '2')
  }

  // Submit data.
  await axios({
    method: "post",
    url: leadUrl,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
  .then(function () {
    //handle success
    return true
  })
  .catch(function () {
    //handle error
    return false
  });

  return true;
}

export default HcpSignUpSubmission
