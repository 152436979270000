import axios from 'axios';

export interface Props {
  email: string;
  province: string;
  isOver18: boolean;
  agreedTerms: boolean;
  experienceGeneTesting: string;
}

/** Rendering template for Patient Sign Up Submission Form
 *
 * @param props props
 * @returns
 */
const PatientSignUpSubmission = async (props: Props) => {
  const { email, province, isOver18, agreedTerms, experienceGeneTesting } = props;

  const leadUrl = `${process.env.PSIGNURL}`
  if (isOver18 && agreedTerms && email && province) {
    // Gather data.
    const bodyFormData = new FormData();
    bodyFormData.append('preferred_language', 'en')
    bodyFormData.append('email_address', email)
    bodyFormData.append('province_territory', province)
    if (experienceGeneTesting !== "") {
      bodyFormData.append('what_is_your_experience_with_genetic_testing_ird', experienceGeneTesting)
    }
    bodyFormData.append('i_am_18_years_or_older', isOver18 ? "1" : "0")
    bodyFormData.append('communication_preference_1', 'Active');

    // Submit data.
    await axios({
      method: "post",
      url: leadUrl,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(function () {
      //handle success
      return true
    })
    .catch(function () {
      //handle error
      return false
    });

    return true;
  }
}

export default PatientSignUpSubmission
