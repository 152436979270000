import React, { useState, useRef, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import { SignUpWrapper } from './styles'
import RetinaModal from '@retina-packages/retina-theme-bootstrap/packages/components/RetinaModal'
import PatientSignUpValidation from './Validation/frmValidation'
import { validateFunc } from "./Validation/validateFunc"
import { submitHandller } from "./Submission/submitHandller"
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap/packages'

type formProfile = {
  propdata: any
  location: any
  successPopData: any
  audience: any
  staticMediaFiles: any
  topContentInfo: string
  optOutContent: string
}

const PatientSignUpForm = (props: formProfile) => {

  const [disabled, setDisabled] = useState(true);
  const [showExitModal, setShowExitModal] = useState(false)

  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const formField = { 'email': '', 'province': '', 'isOver18': '', 'agreedTerms': '', 'referencePath': '' }
  const [isOver18Checked, setIsOver18Checked] = useState("")
  const [isAgreedTermsChecked, setIsAgreedTermsChecked] = useState("")
  const isOver18Ref = useRef(null);
  const agreedTermsRef = useRef(null);

  const setField = async (event: any, field: any, value: any) => {
    await validateFunc({ setForm, form, field, value, errors, setErrors, event, setIsOver18Checked, setIsAgreedTermsChecked, validateField, checkAllField })
  }
  const validateField = async (field: any, value: any) => {
    const fieldValidation = await PatientSignUpValidation({ field, value })
    if (fieldValidation.isError) {
      setErrors({
        ...errors,
        [field]: fieldValidation.Errormsg
      })
    }
  }
  const checkAllField = checkAllFieldBase(formField, form, isOver18Ref, agreedTermsRef, setDisabled)
  const findFormErrors = findFormErrorsBase(setDisabled, formField, form, isOver18Ref, agreedTermsRef)
  const handleSubmit = async (e: any) => {
    await submitHandller({ e, findFormErrors, setErrors, setDisabled, form, isOver18Ref, agreedTermsRef, setShowExitModal })
  }

  useEffect(()=>{
    const escapeEvent = escapeEventBase(setShowExitModal)
    // Hide modal on pressing escape key for accessibility.
    document.addEventListener('keydown', escapeEvent)
    // Hide modal on outside click.
    document.addEventListener('click', hideExitOnClick(setShowExitModal))
  }, [])

  return (
    <>
      <RetinaModal
        location={props.location}
        redirectlink={"/"}
        modalData={{ "headerTxt": "", "description": props.successPopData.description, "footerTxt": "", "modalclass": "patient-signup", "label": "patient_sign_up_form_success" }}
        show={showExitModal}
        closeButtonLabel={'Close'}
        staticMediaFiles={props.staticMediaFiles}
      />

      <SignUpWrapper>
        <div className="sign-up-title form-para o-column--full">
          <Form className="signup-form" onSubmit={handleSubmit} action="#">
            <Form.Group>
            <HTMLRenderer html={props.topContentInfo} data={props.staticMediaFiles} tagName='div'/>
            </Form.Group>
            <div className="o-container-inner">
              <div className="information-form">
                <div className="form signupform">
                  <div className="apos-area">
                    <p className="required-field-text"> All fields are required unless otherwise noted. </p>
                    <Form.Group controlId="validationEmail">
                      <div className="form-field__control gtm-form-signup">
                        <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Email address</Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control name="email"
                            aria-required="true" type="text"
                            className='form-field__input'
                            isInvalid={!!errors.email}
                            onBlur={(e: any) => setField(e, 'email', e.target.value)}
                            { ... errors.email ? {"aria-invalid": true} : {}}
                            { ... errors.email ? {"aria-describedby": 'email-error'} : {}}
                          />
                          <Form.Control.Feedback type='invalid' role="alert" id="email-error">
                            {errors.email}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </div>
                    </Form.Group>

                    <Form.Group controlId="formProvince">
                      <div className="form-field__control gtm-form-signup">
                        <Form.Label className='o-column--full o-text--normal apos-forms-label form-field__label'>Province/Territory</Form.Label>
                        <Form.Select size="sm"
                          aria-required="true"
                          className="apos-forms-input form-field__input optional-form-dw optional-dw-patient"
                          isInvalid={!!errors.province}
                          onBlur={(e: any) => setField(e, 'province', e.target.value)}
                          { ... errors.province ? {"aria-invalid": true} : {}}
                          { ... errors.province ? {"aria-describedby": 'province-error'} : {}}
                        >
                          <option value="">Province/Territory</option>
                          <option value="AB">Alberta</option>
                          <option value="BC">British Columbia</option>
                          <option value="MB">Manitoba</option>
                          <option value="ON">Ontario</option>
                          <option value="NB">New Brunswick</option>
                          <option value="NL">Newfoundland and Labrador</option>
                          <option value="NT">Northwest Territories</option>
                          <option value="NS">Nova Scotia</option>
                          <option value="NU">Nunavut</option>
                          <option value="PE">Prince Edward Island</option>
                          <option value="QC">Quebec</option>
                          <option value="SK">Saskatchewan</option>
                          <option value="YT">Yukon</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid' role="alert" id="province-error">
                          {errors.province}
                        </Form.Control.Feedback>

                      </div>
                    </Form.Group>

                    <Form.Group className="checkbox-section optional gtm-form-signup" controlId="validationRadio">
                      <p className="o-text--normal radio-btn-title">What is your experience with genetic testing for inherited retinal diseases (optional)</p>
                      <Form.Check className='custom-checkbox-btn custom-radio1' label="I have not had genetic testing" name="experienceGeneTesting" type="radio" value="5441" onClick={(e: any) => setField(e, 'experienceGeneTesting', e.target.value)} />
                      <Form.Check className='custom-checkbox-btn custom-radio2' label="I have had genetic testing but received an inconclusive or negative result" name="experienceGeneTesting" type="radio" value="5442" onClick={(e: any) => setField(e, 'experienceGeneTesting', e.target.value)} />
                      <Form.Check className='custom-checkbox-btn custom-radio3' label="I have had genetic testing and confirmed my gene variant (a change in the DNA sequence that makes up my genes)" name="experienceGeneTesting" type="radio" value="5443" onClick={(e: any) => setField(e, 'experienceGeneTesting', e.target.value)} />
                    </Form.Group>

                    <hr ></hr>

                    <Form.Group className="custom-check-btn-container check-container-18" controlId="validationCheckbox">
                      <Form.Check ref={isOver18Ref} aria-required="true" className='custom-checkbox-btn check-18' label="I am 18 years or older." name="isOver18" type="checkbox" value={isOver18Checked} id="isOver18" isInvalid={!!errors.isOver18} onClick={(e: any) => setField(e, 'isOver18', e.target.value)} />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.isOver18}
                      </Form.Control.Feedback>
                      <Form.Check aria-required="true" ref={agreedTermsRef} className='custom-checkbox-btn check-18 check-read-all' label="I have read all terms and would like to register for ongoing communications from Janssen, Inc. and its affiliates." name="agreedTerms" type="checkbox" value={isAgreedTermsChecked} id="agreedTerms" isInvalid={!!errors.agreedTerms} onClick={(e: any) => setField(e, 'agreedTerms', e.target.value)} />
                      <Form.Control.Feedback type='invalid' role="alert">
                        {errors.agreedTerms}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                </div>
              </div>
            </div>
            <div className=" o-container o-column-btn-container patient-submit-btn-container">
              <HTMLRenderer html={props.optOutContent} data={props.staticMediaFiles} tagName='div'/>
              <Form.Group className="o-container o-column-hid-container mb-3 bothid" controlId="validationReferencePath">
                <Form.Control type="text" name="referencePath" className="refpath bothid" onChange={(e: any) => { setField(e, 'referencePath', e.target.value); }} />
              </Form.Group>
              <Button className="o-button o-button--primary form-field__submit gtm-form-signup mb-6" type="submit" disabled={disabled} >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </SignUpWrapper>
    </>
  )
}

export default PatientSignUpForm;
function hideExitOnClick(setShowExitModal: any): (this: Document, ev: MouseEvent) => any {
  return (e: any) => {
    const target = e.target
    const role = target && target.getAttribute('role')
    if (role && role === 'dialog') {
      setShowExitModal(false)
    }
  }
}

function escapeEventBase(setShowExitModal: any) {
  return (e: any) => {
    // Hide modal on pressing escape key for accessibility.
    if (e.key === "Escape") {
      setShowExitModal(false)
    }
  }
}

function findFormErrorsBase(setDisabled: any, formField: { email: string; province: string; isOver18: string; agreedTerms: string; referencePath: string }, form: any, isOver18Ref: any, agreedTermsRef: any) {
  return async () => {
    setDisabled(true)
    const newErrors = []
    for (const key in formField) {
      const field = key
      let value = form[key] === undefined ? "" : form[key]
      if (field === "isOver18") {
        value = isOver18Ref.current.value
      }
      if (field === "agreedTerms") {
        value = agreedTermsRef.current.value
      }
      const fieldValidation = await PatientSignUpValidation({ field, value })
      if (fieldValidation.isError === true) {
        newErrors.push({ "field": [fieldValidation.field], "Errormsg": fieldValidation.Errormsg })
      }
    }
    return newErrors
  }
}

function checkAllFieldBase(formField: { email: string; province: string; isOver18: string; agreedTerms: string; referencePath: string }, form: any, isOver18Ref: any, agreedTermsRef: any, setDisabled: any) {
  return async (inputField: any, inputVal: any) => {
    let newErrors = false
    for (const key in formField) {
      const field = key
      let value = "";
      if (field === inputField) {
        value = inputVal
      }
      else if(form[field] === undefined) {
        // Do nothing.
      }
      else {
        value = form[key]
      }
      if (field === "isOver18") {
        value = isOver18Ref.current.value
      }
      if (field === "agreedTerms") {
        value = agreedTermsRef.current.value
      }
      const fieldValidation = await PatientSignUpValidation({ field, value })
      if (fieldValidation.isError === true) {
        newErrors = true
      }
    }
    newErrors === true ? setDisabled(true) : setDisabled(false)
  }
}

