import { ErrorObj, ErrorMessages, SignUpFields } from "../../types"

export interface Props {
	field?: any
	value?: any
	interstedInfo?: any
}

/** Rendering template for HCP Sign Up Validation
 *
 * @param param0 props
 * @returns
 */
const HcpSignUpValidation = async ({ field, value, interstedInfo }: Props): Promise<ErrorObj> => {
	let errorObj: ErrorObj = { "isError": false, "field": field, "value": value, "Errormsg": ErrorMessages.noError }
	errorObj = validateFields({ field, value, errorObj, interstedInfo })
	return errorObj
}

export default HcpSignUpValidation

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function validateFields({ field, value, errorObj, interstedInfo }: { field: any; value: any; errorObj: ErrorObj; interstedInfo: any; }): ErrorObj {
	const errors: ErrorObj = { "isError": false, "field": field, "value": value, "Errormsg": ErrorMessages.noError}
  const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

	switch (field) {
		case SignUpFields.EmailAddr:
			// eslint-disable-next-line no-case-declarations
			const validEmail = emailReg.test(String(value));
			errors.Errormsg = (!validEmail || value === "") ? ErrorMessages.emailError : ErrorMessages.noError
			errors.isError = (!validEmail || value === "")
			break;
		case SignUpFields.Firstname:
		case SignUpFields.Lastname:
			errors.Errormsg = (value === "") ? ErrorMessages.generalError : ErrorMessages.noError
			errors.isError = (value === "")
			break;
    case SignUpFields.Speciality:
      errors.Errormsg = (value === "") ? ErrorMessages.specialityError : ErrorMessages.noError
      errors.isError = (value === "")
      break;
    case SignUpFields.Province:
        errors.Errormsg = (value === "") ? ErrorMessages.provinceError : ErrorMessages.noError
        errors.isError = (value === "")
        break;
		case SignUpFields.InfoAboutIRDs:
		case SignUpFields.EmergingScienceAroundIRDs:
		case SignUpFields.ConsiderationsToGeneticTestingForIRDs:
			validateInterests(interstedInfo, errors)
			break;
		default:
			errors.Errormsg = ErrorMessages.noError
			errors.isError = false
			break;
	}

	return errors
}

/** Helper function for Interest validation
 *
 * @param interstedInfo object
 * @param errors object
 *
 * @returns void
 */
function validateInterests(interstedInfo: any, errors: ErrorObj) {
	let i = 0
	for (const key in interstedInfo) {
		const val = interstedInfo[key]
		if (val !== "") {
			i++
		}
	}
	if (i === 0) {
		errors.Errormsg = ErrorMessages.checkFieldError
		errors.isError = true
	}
}
