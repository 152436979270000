import HcpSignUpValidation from './frmValidation';

export function hcpValidation(interstedInfo: any, setErrors: any, errors: any) {
  return async (field: any, value: any) => {
    const fieldValidation = await HcpSignUpValidation({ field, value, interstedInfo });
    if (fieldValidation.isError) {
      if (field === "infoAboutIRDs" || field === "considerationsToGeneticTestingForIRDs" || field === "emergingScienceAroundIRDs") {
        field = "interestedReceiveInfo";
      }
      setErrors({
        ...errors,
        [field]: fieldValidation.Errormsg
      });
    }
  };
}
